<template lang="">
    <div>
        <b-card :no-body="modal">            
            <div class="row pr-1">
                <div class="col-12" v-if="!modal">
                    <h4 class="card-title mb-3">
                        {{ $t(`GRUPO_ECONOMICO.TITULO_FORMULARIO_${!id ? "NOVO" : "EDICAO"}`) }}
                    </h4>
                </div>
                <input-text id="input-nome" class="col-12" :label="$t('GRUPO_ECONOMICO.TABELA.NOME')" v-model="form.nome" ref="nome" />
                                
                <div class="col-12 mt-4 d-flex justify-content-end" v-if="!modal">
                    <b-button id="btn-cancelar" @click="cancelar" variant="outline-success">
                        Cancelar
                    </b-button>        
                    <b-button id="btn-salvar" @click="salvar" variant="success" class="ml-2" v-if="form.id ? permissao.editar : permissao.criar">
                        Salvar
                    </b-button>        
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { InputText } from "@/components/inputs";
import GrupoEconomicoService from "@/common/services/grupo_economico/grupo_economico.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import cloneDeep from "lodash.clonedeep";
import helpers from "../../common/utils/helpers";
export default {
  props: {
    id: {},
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputText,
  },
  data: function () {
    return {
      form: {
        nome: "",
      },
    };
  },
  methods: {
    salvar: function () {
      return new Promise((resolve) => {
        this.confirmar(
          this.$t(`GRUPO_ECONOMICO.TITULO_SALVAR`),
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then((confirmado) => {
          if (!confirmado) return;

          if (!this.validarFormulario()) return false;

          this.$store.dispatch(START_LOADING);
          let form = cloneDeep(this.form);
          GrupoEconomicoService.salvar(form)
            .then(() => {
              this.alertaSucesso(
                this.$t("GRUPO_ECONOMICO.MENSAGEM_SUCESSO")
              ).then(() => {
                if (!this.modal) this.$router.push({ name: "grupo-economico" });
                resolve();
              });
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      });
    },
    cancelar: function () {
      this.$router.push({ name: "grupo-economico" });
    },
    buscar: function () {
      this.$store.dispatch(START_LOADING);
      GrupoEconomicoService.buscar(this.id)
        .then((res) => {
          this.form = res.data.data;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      {
        titulo: this.$t("GRUPO_ECONOMICO.TITULO_LISTA"),
        to: "/grupo_economico",
      },
      {
        titulo: this.$t(
          `GRUPO_ECONOMICO.TITULO_FORMULARIO_${!this.id ? "NOVO" : "EDICAO"}`
        ),
      },
    ]);
    if (this.id) {
      this.buscar();
    }
  },
  computed: {
    permissao: function () {
      return helpers.validarAcesso("GrupoEconomico");
    },
  },
};
</script>
<style lang="">
</style>